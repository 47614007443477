// kind of a hack for updating turbo-stream element class attribute
// (replacing turbo_frame with turbo_stream won't update it's class or any attributes except its inner content

import { Controller } from "@hotwired/stimulus";
import Masonry from "masonry-layout";

export default class extends Controller {
  static values = {
    cssClass: String
  }

  connect() {
    let frame = this.element.closest('turbo-frame')

    // replace class list, keeping original and adding col-* class
    frame.setAttribute("class", `feed-item ${this.cssClassValue}`)

    // refresh masonry layout
    masonry.layout()
  }
}
