import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    const sliderContainer = document.querySelector('.slider-container')
    const indicators = document.querySelectorAll('.indicator');

    const imageSlides = document.querySelector('.image-slides');
    const imageSlide = document.querySelectorAll('.image-slide');
    const imageSlideWidth = parseInt(getComputedStyle(sliderContainer).getPropertyValue('--image-slide-width'));
    
    const headingSlides = document.querySelector('.heading-slides');
    const headingSlideWidth = parseInt(getComputedStyle(sliderContainer).getPropertyValue('--heading-slide-width'));
    
    let currentSlide = 0;

    function showSlide(n) {
      // Update active indicator
      for (let i = 0; i < indicators.length; i++) {
        indicators[i].classList.remove('active');
      }
      indicators[n].classList.add('active');

      // Update slider
      currentSlide = n;
      updateSlider();
    }

    // Update the slider to show the current slide
    function updateSlider() {
      imageSlides.style.transform = `translateX(-${currentSlide * imageSlideWidth}%)`;
      headingSlides.style.transform = `translateX(-${currentSlide * headingSlideWidth}%)`;
    }

    // Click event listener for the previous button
    prevBtn.addEventListener('click', () => {
      currentSlide--;
      if (currentSlide < 0) {
        currentSlide = imageSlide.length - 1;
      }
      showSlide(currentSlide);
    });

    // Click event listener for the next button
    nextBtn.addEventListener('click', () => {
      currentSlide++;
      if (currentSlide > imageSlide.length - 1) {
        currentSlide = 0;
      }
      showSlide(currentSlide);
    });

    // Add event listeners to indicators
    for (let i = 0; i < indicators.length; i++) {
      indicators[i].addEventListener('click', function() {
        showSlide(parseInt(this.getAttribute('data-slide')) - 1);
      });
    }
  }
}
