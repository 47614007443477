import { Controller } from "@hotwired/stimulus";
import Masonry from "masonry-layout";

export default class extends Controller {
  connect() {
    window.masonry = new Masonry(this.element, {
      percentPosition: true,
      transitionDuration: '0.3s',
    });
  }
}
